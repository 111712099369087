import revive_payload_client_4EU7fepRiI from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.15.4_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@18.19.76_db0@0.2.4_eslint@_2aoycokfqukxu5fjqb6h5e2vxa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8rHXeTNrZ5 from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.15.4_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@18.19.76_db0@0.2.4_eslint@_2aoycokfqukxu5fjqb6h5e2vxa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6BkEtoDpL8 from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.15.4_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@18.19.76_db0@0.2.4_eslint@_2aoycokfqukxu5fjqb6h5e2vxa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Iypf8NDqOf from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.1.1_@types+node@18.19.76_jiti@2.4.2_sass@1.85.0_n2qh2axgw6pdxsfc4qwwxfg5xi/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_FGyg8LuCeh from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.15.4_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@18.19.76_db0@0.2.4_eslint@_2aoycokfqukxu5fjqb6h5e2vxa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bw3YYB5V0A from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.15.4_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@18.19.76_db0@0.2.4_eslint@_2aoycokfqukxu5fjqb6h5e2vxa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uhEfT6k3uN from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.15.4_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@18.19.76_db0@0.2.4_eslint@_2aoycokfqukxu5fjqb6h5e2vxa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wxt1pN1ZMO from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.15.4_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@18.19.76_db0@0.2.4_eslint@_2aoycokfqukxu5fjqb6h5e2vxa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KfbI4lR8CT from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/library-website-nuxt/library-website-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5gkeLkIPPS from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.15.4_@netlify+blobs@8.1.0_@parcel+watcher@2.5.1_@types+node@18.19.76_db0@0.2.4_eslint@_2aoycokfqukxu5fjqb6h5e2vxa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_c9mh1JAWCq from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.4_@netlify+blobs@8.1.0_@parcel+watc_pstuj3b2k5aurv6zrhyx2jhmum/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_56jazXOU4W from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt-graphql-request@7.0.5_graphql@16.10.0_magicast@0.3.5_rollup@4.34.8/node_modules/nuxt-graphql-request/dist/runtime/plugin.mjs";
import add_vue_skip_to_TpqMnYriJH from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/add-vue-skip-to.js";
import craft_layout_data_client_tC01UjHDpi from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/craft-layout-data.client.js";
import craft_xgLlYngLwv from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/craft.js";
import get_headers_client_gEno0Gq67v from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/get-headers.client.js";
import scrape_formid_client_L5cBRTlafH from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/scrape-formid.client.js";
import update_library_alert_client_kn0IiQ5VL4 from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/update-library-alert.client.js";
export default [
  revive_payload_client_4EU7fepRiI,
  unhead_8rHXeTNrZ5,
  router_6BkEtoDpL8,
  _0_siteConfig_Iypf8NDqOf,
  payload_client_FGyg8LuCeh,
  navigation_repaint_client_bw3YYB5V0A,
  check_outdated_build_client_uhEfT6k3uN,
  chunk_reload_client_wxt1pN1ZMO,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5gkeLkIPPS,
  plugin_c9mh1JAWCq,
  plugin_56jazXOU4W,
  add_vue_skip_to_TpqMnYriJH,
  craft_layout_data_client_tC01UjHDpi,
  craft_xgLlYngLwv,
  get_headers_client_gEno0Gq67v,
  scrape_formid_client_L5cBRTlafH,
  update_library_alert_client_kn0IiQ5VL4
]